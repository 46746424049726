import React from 'react'
import moment from 'moment';
import './LiveTemperaturePanel.css';

class LiveTemperaturePanel extends React.Component {

    render() {
        if (this.props == null || this.props.molen == null || this.props.molen.latestInternalTemperatureReading == null || this.props.molen.latestWeatherObservation == null) {
            return <span>Loading ...</span>
        }
        else {
            return this.renderLoaded();
        }
    }

    renderLoaded() {

        return (

            <div className="panel-narrow">
                <div className="panel-header">
                    <div className="panel-top-left">
                        LIVE TEMPERATURE
                    </div>
                    <div className="panel-top-right">
                    </div>
                </div>
                <div className="panel-body">

                    <div className="panel-columns-span-width">
                        <div className="internal-temp-panel">
                            <div className="app-panel-header-text">INTERNAL</div>
                            {(this.props.molen.latestInternalTemperatureReading.status === "OK") ? <>
                                <div className="app-oversize-text">{this.props.molen.latestInternalTemperatureReading.tempDegC.toFixed(1)}</div>
                                &deg;C<br />
                                <div className="app-supersmalltext">
                                    <br />
                                    Reported {moment(this.props.molen.latestInternalTemperatureReading.utcTime).format("HH:mm:ss")} by<br />
                                    mill sensor
                                </div>
                            </> : <>
                                <div className="app-oversize-text">-&nbsp;</div><br />
                                <div className="app-supersmalltext">
                                    <br />
                                    Last contact from internal sensor at {moment(this.props.molen.latestInternalTemperatureReading.utcTime).format("HH:mm:ss")} on {moment(this.props.molen.latestInternalTemperatureReading.utcTime).format("DD/MM/yyyy")}<br />
                                </div>
                            </>}

                        </div>

                        <div className="external-temp-panel">
                            <div className="app-panel-header-text">EXTERNAL</div>
                            <div className="app-oversize-text">{this.props.molen.latestWeatherObservation.tempDegC != null ? this.props.molen.latestWeatherObservation.tempDegC.toFixed(1) : "-"}</div>
                            &deg;C<br />
                            <div className="app-supersmalltext">
                                <br />
                                Reported {moment(this.props.molen.latestWeatherObservation.utcTime).format("HH:mm:ss")} by<br />
                                <a target="_blank" rel="noreferrer" href={this.props.molen.latestWeatherObservation.weatherSourceLink}>{this.props.molen.latestWeatherObservation.weatherSourceName}</a>
                                {this.props.molen.latestWeatherObservation.weatherSourceLink.length === 0 && <>{this.props.molen.latestWeatherObservation.weatherSourceName}</>}
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        )
    }
}

export default LiveTemperaturePanel
