import React from 'react';
import { Link, Switch, Route, matchPath } from 'react-router-dom';
import OrientationSensorDetails from '../../components/orientationSensorDetails/orientationSensorDetails'
import SailBrakeFanSensorDetails from '../../components/sailBrakeFanSensorDetails/sailBrakeFanSensorDetails';
import SailRotationDetails from '../../components/sailRotationSensorDetails/sailRotationDetails';

class TechSensorTab extends React.Component {

  topNavClass = "topnav";

  onBurgerClick() {
    if (this.topNavClass === "topnav") {
      this.topNavClass = "topnav responsive";
    } else {
      this.topNavClass = "topnav";
    }
  }


  render() {

    var magnetometer1Present = this.props.molen.orientationSensors != null && this.props.molen.orientationSensors.length >= 1;
    var magnetometer2Present = this.props.molen.orientationSensors != null && this.props.molen.orientationSensors.length >= 2;
    var sailRotationSensorPresent = this.props.molen.latestSailRotationReading != null && this.props.molen.latestSailRotationReading.sailRotationDataSource === "DRAGINO_LSN50V2";
    var sailBrakeFanSensorPresent = this.props.molen.latestSailRotationReading != null && this.props.molen.latestSailRotationReading.sailRotationDataSource !== "DRAGINO_LSN50V2"; // TODO - fix to support other types

    var defaultPage = magnetometer1Present ? "MAGNETOMETER_1" : sailRotationSensorPresent ? "SAIL_ROTATION" : null;

    var pathPrefix = '/';
    if (window.location.pathname.startsWith('/live/')) { pathPrefix = "/live/"; }
    if (window.location.pathname.startsWith('/tech/')) { pathPrefix = "/tech/"; }

    var orientationSensor1Link = matchPath(window.location.pathname, { path: pathPrefix + this.props.molen.shortName + '/sensor', exact: true, strict: true }) != null ? "active" : "link";
    var orientationSensor2Link = matchPath(window.location.pathname, { path: pathPrefix + this.props.molen.shortName + '/sensor/magnetometer2', }) != null ? "active" : "link";
    var sailBrakeFanSensorLink = matchPath(window.location.pathname, { path: pathPrefix + this.props.molen.shortName + '/sensor/sailBrakefan', }) != null ? "active" : "link";
    var sailSensorLink = matchPath(window.location.pathname, { path: pathPrefix + this.props.molen.shortName + '/sensor/sails', }) != null || defaultPage === "SAIL_ROTATION" ? "active" : "link";

    return (
      <div width="100%">
        <div className={this.topNavClass}>
          {magnetometer1Present && <Link to={pathPrefix + this.props.molen.shortName + '/sensor'} className={orientationSensor1Link}>Magnetometer 1</Link>}
          {magnetometer2Present && <Link to={pathPrefix + this.props.molen.shortName + '/sensor/magnetometer2'} className={orientationSensor2Link}>Magnetometer 2</Link>}
          {sailBrakeFanSensorPresent && <Link to={pathPrefix + this.props.molen.shortName + '/sensor/sailBrakefan'} className={sailBrakeFanSensorLink}>Sail/Brake/Fan</Link>}
          {sailRotationSensorPresent && <Link to={pathPrefix + this.props.molen.shortName + '/sensor/sails'} className={sailSensorLink}>Sail Rotation</Link>}
          <a href="# " className="icon" onClick={ev => { this.onBurgerClick(); }}>
            <i className="fa fa-bars"></i>
          </a>
        </div>
        <div className="tabs">
          <Switch>
            <Route path={'/tech/:molenName/sensor/magnetometer2'} render={(props) => ((this.props.molen.orientationSensors != null && this.props.molen.orientationSensors.length >= 2) && <OrientationSensorDetails molen={this.props.molen} sensorFriendlyId={2} />)} />
            <Route path={'/live/:molenName/sensor/magnetometer2'} render={(props) => ((this.props.molen.orientationSensors != null && this.props.molen.orientationSensors.length >= 2) && <OrientationSensorDetails molen={this.props.molen} sensorFriendlyId={2} />)} />
            <Route path={'/:molenName/sensor/magnetometer2'} render={(props) => ((this.props.molen.orientationSensors != null && this.props.molen.orientationSensors.length >= 2) && <OrientationSensorDetails molen={this.props.molen} sensorFriendlyId={2} />)} />

            <Route path={'/tech/:molenName/sensor/sailBrakefan'} render={(props) => (<SailBrakeFanSensorDetails molen={this.props.molen} />)} />
            <Route path={'/live/:molenName/sensor/sailBrakefan'} render={(props) => (<SailBrakeFanSensorDetails molen={this.props.molen} />)} />
            <Route path={'/:molenName/sensor/sailBrakefan'} render={(props) => (<SailBrakeFanSensorDetails molen={this.props.molen} />)} />

            <Route path={'/tech/:molenName/sensor/sails'} render={(props) => (<SailRotationDetails molen={this.props.molen} />)} />
            <Route path={'/live/:molenName/sensor/sails'} render={(props) => (<SailRotationDetails molen={this.props.molen} />)} />
            <Route path={'/:molenName/sensor/sails'} render={(props) => (<SailRotationDetails molen={this.props.molen} />)} />

            <Route path={'/tech/:molenName/sensor'} render={(props) => ((defaultPage === "MAGNETOMETER_1") ? <OrientationSensorDetails molen={this.props.molen} sensorFriendlyId={1} /> : <SailRotationDetails molen={this.props.molen} />)} />
            <Route path={'/live/:molenName/sensor'} render={(props) => ((defaultPage === "MAGNETOMETER_1") ? <OrientationSensorDetails molen={this.props.molen} sensorFriendlyId={1} /> : <SailRotationDetails molen={this.props.molen} />)} />
            <Route path={'/:molenName/sensor'} render={(props) => ((defaultPage === "MAGNETOMETER_1") ? <OrientationSensorDetails molen={this.props.molen} sensorFriendlyId={1} /> : <SailRotationDetails molen={this.props.molen} />)} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default TechSensorTab