import React from 'react';
import './WhirlOffFeaturedMill.css';
import WhirlOffFeaturedMillImage from './WhirlOffFeaturedMillImage';
import WhirlOffFeaturedMillInfoPanel from './WhirlOffFeaturedMillInfoPanel';

class WhirlOffFeaturedMill extends React.Component {

    componentDidMount() {
    }

    render() {
        if (this.props.millToDisplay == null) {
        }
        else {
            return this.renderLoaded();
        }
    }

    renderLoaded() {

        return (

            <>
                <div className="whirloff-featured-mill-container">
                    <div className="whirloff-featured-mill-left">
                        <WhirlOffFeaturedMillInfoPanel millToDisplay={this.props.millToDisplay} whirloff={this.props.whirloff} highlightColor={this.props.highlightColor} />
                    </div>
                    <div className="whirloff-featured-mill-right">
                        <WhirlOffFeaturedMillImage millToDisplay={this.props.millToDisplay} />
                    </div>
                </div>
            </>
        )
    }
}

export default WhirlOffFeaturedMill