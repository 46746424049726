import React from 'react';

class SailRotationDiagramDutch
    extends React.Component {

    componentDidMount() {
    }

    render() {
        if (this.props == null || (this.props.molen == null && this.props.currentSpeedRpm == null)) {
            return <span>Loading ...</span>
        }
        else {
            return this.renderLoaded();
        }
    }

    renderLoaded() {

        var rpm;
        var rotationTimePeriod;
        var size = "188px";

        if (this.props.molen != null) {
            if (this.props.molen.latestSailRotationReading.currentSpeedRpm > 0) {
                rpm = this.props.molen.latestSailRotationReading.currentSpeedRpm;
                rotationTimePeriod = Math.abs(60.0 / rpm);
            }
            else {
                rpm = 0;
                rotationTimePeriod = 0;
            }
        }
        else
        {
            rpm = this.props.currentSpeedRpm;
            rotationTimePeriod = Math.abs(60.0 / rpm);
        }

        if (this.props.windmillSize != null)
        {
            size = "" + this.props.windmillSize + "px";
        }

        var rotationDirectionExpression = rpm < 0 ? "0;360" : "360;0";

        return (
            <>

                <div>
                    <svg viewBox="0 41.1 288 288" width={size} height={size}>
                        <defs>
                            <linearGradient gradientUnits="userSpaceOnUse" x1="143.998" y1="180.393" x2="143.998" y2="185.365" id="gradient-0" gradientTransform="matrix(1, 0, 0, 1, -287.995972, -365.758057)">
                                <stop offset="0" style={{ stopColor: "rgb(54.118% 50.196% 50.196%)" }} />
                                <stop offset="1" style={{ stopColor: "rgb(32.866% 29.312% 29.322%)" }} />
                            </linearGradient>
                            <linearGradient gradientUnits="userSpaceOnUse" x1="145.768" y1="161.146" x2="145.768" y2="292.856" id="gradient-2" gradientTransform="matrix(-0.034556, -0.999403, 0.321395, -0.011112, 81.494648, 383.105681)">
                                <stop offset="0" style={{ stopColor: "rgb(32.549% 20% 11.765%)" }} />
                                <stop offset="1" style={{ stopColor: "rgb(23.097% 11.626% 2.6713%)" }} />
                            </linearGradient>
                            <linearGradient gradientUnits="userSpaceOnUse" x1="144.001" y1="234.636" x2="144.001" y2="249.914" id="gradient-3" gradientTransform="matrix(0.149348, -0.988785, 0.814428, 0.123013, -74.397952, 353.779705)">
                                <stop offset="0" style={{ stopColor: "rgb(10.196% 29.412% 30.196%)" }} />
                                <stop offset="1" style={{ stopColor: "rgb(0% 19.156% 20.018%)" }} />
                            </linearGradient>
                            <linearGradient gradientUnits="userSpaceOnUse" x1="143.999" y1="149.829" x2="143.999" y2="181.038" id="gradient-4">
                                <stop offset="0" style={{ stopColor: "rgb(10.196% 29.412% 30.196%)" }} />
                                <stop offset="1" style={{ stopColor: "rgb(0% 19.156% 20.018%)" }} />
                            </linearGradient>
                            <linearGradient gradientUnits="userSpaceOnUse" x1="144.001" y1="-190.383" x2="144.001" y2="-181.414" id="gradient-5" gradientTransform="matrix(1, 0, 0, 1, 0, 0)">
                                <stop offset="0" style={{ stopColor: "rgb(10.196% 29.412% 30.196%)" }} />
                                <stop offset="1" style={{ stopColor: "rgb(0% 19.156% 20.018%)" }} />
                            </linearGradient>
                            <linearGradient gradientUnits="userSpaceOnUse" x1="-144" y1="-182.029" x2="-144" y2="-178.2" id="gradient-6" gradientTransform="matrix(1, 0, 0, 1, 287.998052, 360.229437)">
                                <stop offset="0" style={{ stopColor: "rgb(79.608% 72.549% 49.412%)" }} />
                                <stop offset="1" style={{ stopColor: "rgb(47.34% 41.674% 20.215%)" }} />
                            </linearGradient>
                            <linearGradient gradientUnits="userSpaceOnUse" x1="-144" y1="-182.278" x2="-144" y2="-178.225" id="gradient-7" gradientTransform="matrix(1, 0, 0, 1, 287.990087, 360.501287)">
                                <stop offset="0" style={{ stopColor: "rgb(79.608% 72.549% 49.412%)" }} />
                                <stop offset="1" style={{ stopColor: "rgb(47.34% 41.674% 20.215%)" }} />
                            </linearGradient>
                            <linearGradient gradientUnits="userSpaceOnUse" x1="-198.322" y1="-181.887" x2="-198.322" y2="-178.811" id="gradient-8" gradientTransform="matrix(1, 0, 0, 1, 0.000067, 0.00008)">
                                <stop offset="0" style={{ stopColor: "rgb(79.608% 72.549% 49.412%)" }} />
                                <stop offset="1" style={{ stopColor: "rgb(47.34% 41.674% 20.215%)" }} />
                            </linearGradient>
                            <linearGradient gradientUnits="userSpaceOnUse" x1="-198.322" y1="-181.887" x2="-198.322" y2="-178.811" id="gradient-9" gradientTransform="matrix(1, 0, 0, 1, -0.00007, -0.000001)">
                                <stop offset="0" style={{ stopColor: "rgb(79.608% 72.549% 49.412%)" }} />
                                <stop offset="1" style={{ stopColor: "rgb(47.34% 41.674% 20.215%)" }} />
                            </linearGradient>
                            <linearGradient gradientUnits="userSpaceOnUse" x1="-178.274" y1="-147.899" x2="-178.274" y2="-145.398" id="gradient-10" gradientTransform="matrix(1, 0, 0, 1, 496.038077, 295.798071)">
                                <stop offset="0" style={{ stopColor: "rgb(79.608% 72.549% 49.412%)" }} />
                                <stop offset="1" style={{ stopColor: "rgb(47.34% 41.674% 20.215%)" }} />
                            </linearGradient>
                            <linearGradient gradientUnits="userSpaceOnUse" x1="317.764" y1="147.899" x2="317.764" y2="150.4" id="gradient-11" gradientTransform="matrix(1, 0, 0, 1, 0.000023, -0.000102)">
                                <stop offset="0" style={{ stopColor: "rgb(79.608% 72.549% 49.412%)" }} />
                                <stop offset="1" style={{ stopColor: "rgb(47.34% 41.674% 20.215%)" }} />
                            </linearGradient>
                            <linearGradient gradientUnits="userSpaceOnUse" x1="144.026" y1="150.765" x2="144.026" y2="204.792" id="gradient-1" gradientTransform="matrix(0.773207, -0.634154, 0.166956, 0.203565, -0.341473, 234.475544)">
                                <stop offset="0" style={{ stopColor: "rgb(36.863% 30.588% 27.451%)" }} />
                                <stop offset="1" style={{ stopColor: "rgb(24.014% 18.336% 15.462%)" }} />
                            </linearGradient>
                            <linearGradient gradientUnits="userSpaceOnUse" x1="394.73" y1="201.865" x2="394.73" y2="327.513" id="gradient-12" gradientTransform="matrix(-0.013652, -0.999907, 0.06278, -0.000857, 382.990711, 668.82343)">
                                <stop offset="0" style={{ stopColor: "rgb(10.196% 29.412% 30.196%)" }} />
                                <stop offset="1" style={{ stopColor: "rgb(0% 19.156% 20.018%)" }} />
                            </linearGradient>
                            <linearGradient gradientUnits="userSpaceOnUse" x1="394.73" y1="201.865" x2="394.73" y2="327.513" id="gradient-13" gradientTransform="matrix(-0.013652, -0.999907, 0.06278, -0.000857, 382.990711, 668.82343)">
                                <stop offset="0" style={{ stopColor: "rgb(10.196% 29.412% 30.196%)" }} />
                                <stop offset="1" style={{ stopColor: "rgb(0% 19.156% 20.018%)" }} />
                            </linearGradient>
                            <linearGradient gradientUnits="userSpaceOnUse" x1="394.73" y1="201.865" x2="394.73" y2="327.513" id="gradient-14" gradientTransform="matrix(-0.013652, -0.999907, 0.06278, -0.000857, 382.990711, 668.82343)">
                                <stop offset="0" style={{ stopColor: "rgb(10.196% 29.412% 30.196%)" }} />
                                <stop offset="1" style={{ stopColor: "rgb(0% 19.156% 20.018%)" }} />
                            </linearGradient>
                            <linearGradient gradientUnits="userSpaceOnUse" x1="394.73" y1="201.865" x2="394.73" y2="327.513" id="gradient-15" gradientTransform="matrix(-0.013652, -0.999907, 0.06278, -0.000857, 382.990711, 668.82343)">
                                <stop offset="0" style={{ stopColor: "rgb(10.196% 29.412% 30.196%)" }} />
                                <stop offset="1" style={{ stopColor: "rgb(0% 19.156% 20.018%)" }} />
                            </linearGradient>

                        </defs>
                        <g transform="matrix(1, 0, 0, 1, 0, 15)">
                            <title>Base</title>
                            <g>
                                <title>Tailpole</title>
                                <rect x="248.019" y="147.899" width="139.49" height="2.501" style={{ fill: "url(#gradient-11)", transformOrigin: "317.768px 149.15px" }} transform="matrix(0.390686, 0.920524, -0.920524, 0.390686, -194.707392, 95.149085)" />
                                <rect x="248.019" y="147.899" width="139.49" height="2.501" style={{ fill: "url(#gradient-10)", transformOrigin: "317.768px 149.15px" }} transform="matrix(-0.390686, 0.920524, 0.920524, 0.390686, -153.436163, 94.842125)" />
                                <rect x="-275.91" y="-181.887" width="155.176" height="3.076" style={{ fill: "url(#gradient-8)", transformBox: "fill-box", transformOrigin: "0.13655% 50.022%" }} transform="matrix(-0.554793, 0.831988, 0.831988, 0.554793, 493.500528, 361.359983)" />
                                <rect x="-275.91" y="-181.887" width="155.176" height="3.076" style={{ fill: "url(#gradient-9)", transformBox: "fill-box", transformOrigin: "0.55176% 57.9942%" }} transform="matrix(0.54383, 0.839195, -0.839195, 0.54383, 344.851306, 360.568843)" />
                                <rect x="66.402" y="178.224" width="155.176" height="4.053" style={{ fill: "url(#gradient-7)", transformOrigin: "143.995px 180.251px" }} />
                            </g>
                            <path d="M 113.328 180.373 L 174.629 180.373 L 185.697 312.083 L 102.303 312.083 L 113.328 180.373 Z" style={{ fill: "url(#gradient-2)" }}>
                                <title>Tower</title>
                            </path>
                            <g>
                                <g>
                                    <path style={{ fill: "url(#gradient-1)", transformBox: "fill-box", transformOrigin: "50% 50%" }} d="M 115.815 157.23 C 115.815 157.23 145.099472699561 148.68468762169846 154.625 151.48 C 161.4674768873523 153.4879581673737 167.0367469295858 158.2575913704756 169.872 164.798 C 173.86588694835646 174.01116440319007 168.477 204.945 168.477 204.945" transform="matrix(0.736934, -0.675965, 0.675965, 0.736934, 0, 0.000012)">
                                        <title>Cap</title>
                                    </path>
                                    <rect x="-175.602" y="-185.365" width="63.208" height="4.972" style={{ fill: "url(#gradient-0)", transformBox: "fill-box", transformOrigin: "50% 50%" }} transform="matrix(-1, 0, 0, -1, 287.996006, 365.758003)" />
                                    <path d="M 140.608 152.451 Q 144 147.207 147.391 152.451 L 162.489 175.794 Q 165.88 181.038 159.097 181.038 L 128.902 181.038 Q 122.119 181.038 125.51 175.794 Z" style={{ fill: "url(#gradient-4)" }} />
                                </g>
                                <path d="M 140.609 -189.63 Q 144.001 -191.137 147.392 -189.63 L 162.49 -182.921 Q 165.881 -181.414 159.098 -181.414 L 128.903 -181.414 Q 122.12 -181.414 125.511 -182.921 Z" style={{ fill: "url(#gradient-5)" }} transform="matrix(1, 0, 0, -1, 0, 0)" />
                            </g>
                            <g>
                                <title>Door</title>
                                <rect x="135.08" y="287.136" width="17.839" height="24.557" style={{ fill: "rgb(26, 75, 77)" }} />
                                <ellipse style={{ fill: "rgb(26, 75, 77)" }} cx="144.435" cy="287.741" rx="8.965" ry="9.581" transform="matrix(0.999996, 0.002694, 0, 1.000004, -0.43453, -0.389241)" />
                            </g>
                            <rect x="123.15" y="178.2" width="41.696" height="3.829" style={{ fill: "url(#gradient-6)", transformOrigin: "143.999px 180.114px" }} rx="1.914" ry="1.914">
                                <title>Weatherbeam cover</title>
                            </rect>
                            <rect x="137.727" y="234.636" width="12.547" height="15.278" style={{ fill: "url(#gradient-3)" }}>
                                <title>Window</title>
                            </rect>
                        </g>

                        <g style={{ transformOrigin: "144px 168.781px" }} transform="matrix(0.998621, -0.052493, 0.052493, 0.998621, -0.000006, 15.000006)">
                            <g style={{ transformOrigin: "144px 168.773px" }} transform="matrix(0.998554, 0.05376, -0.05376, 0.998554, 0, 0)">
                                <g transform="matrix(1, 0, 0, 1, -244.215778, -26.835781)">
                                    <g style={{ transformOrigin: "381.636px 258.623px" }} transform="matrix(-0.934609, 0, 0, -0.934609, 14.53465, -132.536242)">
                                        <rect x="362.737" y="209.137" width="25.005" height="118.454" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} />
                                        <rect x="371.268" y="204.611" width="8.464" height="122.932" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} />
                                        <rect x="389.993" y="201.865" width="9.475" height="125.648" style={{ fill: "url(#gradient-13)" }} />
                                        <rect x="397.783" y="201.687" width="2.751" height="125.996" style={{ fill: "rgb(241, 241, 241)" }} />
                                        <rect x="-390.247" y="322.813" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.282" y="313.287" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.252" y="303.74" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.287" y="294.214" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.263" y="285.02" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.298" y="275.494" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.268" y="265.947" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.303" y="256.421" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.361" y="275.498" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.396" y="265.972" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.366" y="256.425" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.401" y="246.899" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.377" y="237.705" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.412" y="228.179" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.382" y="218.632" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.417" y="209.106" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.105" y="204.571" width="18.833" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="387.284" y="190.687" width="5.611" height="138.104" style={{ fill: "rgb(9, 9, 9)" }} />
                                        <rect x="387.198" y="182.44" width="5.706" height="19.417" style={{ fill: "rgb(237, 237, 237)" }} />
                                    </g>
                                    <g transform="matrix(0.934609, 0, 0, 0.934609, 23.815207, 23.418579)">
                                        <rect x="362.737" y="209.137" width="25.005" height="118.454" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} />
                                        <rect x="371.268" y="204.611" width="8.464" height="122.932" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} />
                                        <rect x="389.993" y="201.865" width="9.475" height="125.648" style={{ fill: "url(#gradient-13)" }} />
                                        <rect x="397.783" y="201.687" width="2.751" height="125.996" style={{ fill: "rgb(241, 241, 241)" }} />
                                        <rect x="-390.247" y="322.813" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.282" y="313.287" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.252" y="303.74" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.287" y="294.214" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.263" y="285.02" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.298" y="275.494" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.268" y="265.947" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.303" y="256.421" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.361" y="275.498" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.396" y="265.972" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.366" y="256.425" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.401" y="246.899" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.377" y="237.705" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.412" y="228.179" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.382" y="218.632" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.417" y="209.106" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.105" y="204.571" width="18.833" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="387.284" y="190.687" width="5.611" height="138.104" style={{ fill: "rgb(9, 9, 9)" }} />
                                        <rect x="387.198" y="182.44" width="5.706" height="19.417" style={{ fill: "rgb(237, 237, 237)" }} />
                                    </g>
                                </g>
                                <g transform="matrix(0, 1, -1, 0, -244.215793, -27.516697)" style={{ transformOrigin: "388.216px 195.617px" }}>
                                    <g style={{ transformOrigin: "381.636px 258.623px" }} transform="matrix(-0.934609, 0, 0, -0.934609, 14.53465, -132.536242)">
                                        <rect x="362.737" y="209.137" width="25.005" height="118.454" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} />
                                        <rect x="371.268" y="204.611" width="8.464" height="122.932" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} />
                                        <rect x="389.993" y="201.865" width="9.475" height="125.648" style={{ fill: "url(#gradient-13)" }} />
                                        <rect x="397.783" y="201.687" width="2.751" height="125.996" style={{ fill: "rgb(241, 241, 241)" }} />
                                        <rect x="-390.247" y="322.813" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.282" y="313.287" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.252" y="303.74" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.287" y="294.214" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.263" y="285.02" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.298" y="275.494" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.268" y="265.947" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.303" y="256.421" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.361" y="275.498" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.396" y="265.972" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.366" y="256.425" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.401" y="246.899" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.377" y="237.705" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.412" y="228.179" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.382" y="218.632" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.417" y="209.106" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.105" y="204.571" width="18.833" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="387.284" y="190.687" width="5.611" height="138.104" style={{ fill: "rgb(9, 9, 9)" }} />
                                        <rect x="387.198" y="182.44" width="5.706" height="19.417" style={{ fill: "rgb(237, 237, 237)" }} />
                                    </g>
                                    <g transform="matrix(0.934609, 0, 0, 0.934609, 23.719635, 23.423441)">
                                        <rect x="362.737" y="209.137" width="25.005" height="118.454" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} />
                                        <rect x="371.268" y="204.611" width="8.464" height="122.932" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} />
                                        <rect x="389.993" y="201.865" width="9.475" height="125.648" style={{ fill: "url(#gradient-13)" }} />
                                        <rect x="397.783" y="201.687" width="2.751" height="125.996" style={{ fill: "rgb(241, 241, 241)" }} />
                                        <rect x="-390.247" y="322.813" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.282" y="313.287" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.252" y="303.74" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.287" y="294.214" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.263" y="285.02" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.298" y="275.494" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.268" y="265.947" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.303" y="256.421" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.361" y="275.498" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.396" y="265.972" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.366" y="256.425" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.401" y="246.899" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.377" y="237.705" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.412" y="228.179" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.382" y="218.632" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.417" y="209.106" width="27.496" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="-390.105" y="204.571" width="18.833" height="4.666" style={{ fill: "none", stroke: "rgb(60, 20, 0)" }} transform="matrix(-1, 0, 0, 1, 0, 0)" />
                                        <rect x="387.284" y="190.687" width="5.611" height="138.104" style={{ fill: "rgb(9, 9, 9)" }} />
                                        <rect x="387.198" y="182.44" width="5.706" height="19.417" style={{ fill: "rgb(237, 237, 237)" }} />
                                    </g>
                                </g>
                            </g>
                            <g transform="matrix(1, 0, 0, 1, 0.121392, -16.81412)">
                                <title>Cannister</title>
                                <rect x="140.629" y="178.066" width="6.742" height="14.068" style={{ fill: "rgb(138, 0, 0)" }} />
                                <ellipse style={{ fill: "rgb(138, 0, 0)" }} cx="144" cy="185.1" rx="4.209" ry="6.346" />
                                <g transform="matrix(1, 0, 0, 1, -244.779743, -10.108319)">
                                    <g transform="matrix(1, 0, 0, 1, 0.627304, 0.169894)">
                                        <g>
                                            <path d="M 355.551 189.063 L 355.897 191.267 L 355.204 191.267 L 355.551 189.063 Z" style={{ fill: "rgb(234, 234, 234)" }} transform="matrix(1.000000000002, -4e-12, 3e-12, 1.000000000009, 32.637829313899, 3.01606564072)" />
                                            <ellipse style={{ fill: "rgb(234, 234, 234)" }} cx="388.231" cy="195.071" rx="0.889" ry="0.889" />
                                            <path d="M 355.551 189.063 L 355.897 191.267 L 355.204 191.267 L 355.551 189.063 Z" style={{ fill: "rgb(234, 234, 234)", transformBox: "fill-box", transformOrigin: "50% 50%" }} transform="matrix(-1, 0, 0, -1, 32.674205, 6.741713)" />
                                        </g>
                                        <g transform="matrix(0, 1, -1, 0, -0.030417, 0.013242)" style={{ transformOrigin: "388.231px 195.044px" }}>
                                            <path d="M 355.551 189.063 L 355.897 191.267 L 355.204 191.267 L 355.551 189.063 Z" style={{ fill: "rgb(234, 234, 234)" }} transform="matrix(1.000000000002, -4e-12, 3e-12, 1.000000000009, 32.637829313899, 3.01606564072)" />
                                            <ellipse style={{ fill: "rgb(234, 234, 234)" }} cx="388.231" cy="195.071" rx="0.889" ry="0.889" />
                                            <path d="M 355.551 189.063 L 355.897 191.267 L 355.204 191.267 L 355.551 189.063 Z" style={{ fill: "rgb(234, 234, 234)", transformBox: "fill-box", transformOrigin: "50% 50%" }} transform="matrix(-1, 0, 0, -1, 32.674205, 6.741713)" />
                                        </g>
                                    </g>
                                    <g transform="matrix(0.691315, 0.722554, -0.722554, 0.691315, 0.578875, 0.164616)" style={{ transformOrigin: "388.201px 195.044px" }}>
                                        <g>
                                            <path d="M 355.551 189.063 L 355.897 191.267 L 355.204 191.267 L 355.551 189.063 Z" style={{ fill: "rgb(234, 234, 234)" }} transform="matrix(1.000000000002, -4e-12, 3e-12, 1.000000000009, 32.637829313899, 3.01606564072)" />
                                            <ellipse style={{ fill: "rgb(234, 234, 234)" }} cx="388.231" cy="195.071" rx="0.889" ry="0.889" />
                                            <path d="M 355.551 189.063 L 355.897 191.267 L 355.204 191.267 L 355.551 189.063 Z" style={{ fill: "rgb(234, 234, 234)", transformBox: "fill-box", transformOrigin: "50% 50%" }} transform="matrix(-1, 0, 0, -1, 32.674205, 6.741713)" />
                                        </g>
                                        <g transform="matrix(0, 1, -1, 0, -0.030417, 0.013242)" style={{ transformOrigin: "388.231px 195.044px" }}>
                                            <path d="M 355.551 189.063 L 355.897 191.267 L 355.204 191.267 L 355.551 189.063 Z" style={{ fill: "rgb(234, 234, 234)" }} transform="matrix(1.000000000002, -4e-12, 3e-12, 1.000000000009, 32.637829313899, 3.01606564072)" />
                                            <ellipse style={{ fill: "rgb(234, 234, 234)" }} cx="388.231" cy="195.071" rx="0.889" ry="0.889" />
                                            <path d="M 355.551 189.063 L 355.897 191.267 L 355.204 191.267 L 355.551 189.063 Z" style={{ fill: "rgb(234, 234, 234)", transformBox: "fill-box", transformOrigin: "50% 50%" }} transform="matrix(-1, 0, 0, -1, 32.674205, 6.741713)" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <animateTransform type="rotate" additive="sum" attributeName="transform" values={rotationDirectionExpression} dur={String(rotationTimePeriod) + "s"} fill="freeze" repeatCount="indefinite" keyTimes="0; 1" />
                        </g>
                    </svg>
                </div>
            </>
        )
    }
}



export default SailRotationDiagramDutch