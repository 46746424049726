import React from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import SailRotationDiagramDutch from '../components/sailRotationDiagram/sailRotationDiagramDutch';
import Odometer from 'react-odometerjs';
import './Home2WhirlOffBanner.css';

class Home2WhirlOffBanner extends React.Component {

    lastReloadTimeMillis = 0;


    componentDidMount() {

        const hubConnection = new HubConnectionBuilder()
            .withUrl(`https://` + process.env.REACT_APP_SIGNALR_API)
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect()
            .build();

        console.log(hubConnection);

        this.setState({ hubConnection }, () => {
            this.state.hubConnection
                .start()
                .then(() => {
                    console.log('SignalR Connection started from WhirlOff2');
                })
                .catch(err => {
                    console.log('Error while establishing SignalR connection from WhirlOff2 :(');
                    console.log(err);
                });


            this.state.hubConnection.on('molenUpdated',
                (receivedMessage) => {
                    this.reloadState();
                }
            );

        });

        this.reloadState();
    }

    reloadState() {
        if (this.lastReloadTimeMillis <= (new Date().getTime()) - 10000) {
            var year = new Date().getFullYear();
            fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/whirloff/${year}`)
                .then(response => response.json())
                .then(response => {
                    this.setState({
                        whirloff: response,
                        year: year
                    })
                });
            this.lastLogTimeMillis = new Date().getTime();
        }

    }

    render() {
        if (this.state == null || this.state.whirloff == null || this.state.whirloff.length === 0) {
            return <></>;
        }
        else {
            return this.renderLoaded();
        }
    }

    renderLoaded() {

        var totalSpeed = 0.0;
        var grandTotal = 0;
        var millsTurning = 0;
        var currentlyTurning = null;

        for (let j = 0; j < this.state.whirloff.length; j++) {
            var mill = this.state.whirloff[j];
            if (mill.latestSailRotationReading.currentSpeedRpm > 0) { millsTurning++; currentlyTurning = mill; }
            grandTotal += mill.latestSailRotationReading.revCountThisYear;
            totalSpeed += (mill.info == null || mill.info.sailDirection === "ANTI-CLOCKWISE") ? mill.latestSailRotationReading.currentSpeedRpm : 0 - mill.latestSailRotationReading.currentSpeedRpm;
        }

        var leader = this.state.whirloff[0];
        var highlightColor = millsTurning > 0 ? "#109010" : "#644109";

        var lowerText = "" + millsTurning + " mills turning now";
        if (millsTurning === 0) { lowerText = "Leader: " + leader.name + ", " + (leader.county != null ? leader.county : "") + (leader.city != null ? leader.city: "") + ", " + leader.country };
        if (millsTurning === 1) { lowerText = "Turning now: " + currentlyTurning.name + ", " + (currentlyTurning.county != null ? currentlyTurning.county : "") + (currentlyTurning.city != null ? currentlyTurning.city: "") + ", " + currentlyTurning.country }

        return (
            <>
                <div className="home2-whirloff-banner-content" style={{ backgroundColor: highlightColor }}>
                    <div className="home2-whirloff-banner-left">
                        <SailRotationDiagramDutch currentSpeedRpm={totalSpeed} windmillSize={80} />
                    </div>

                    <div className="home2-whirloff-banner-right">
                        <div className="home2-whirloff-banner-title">
                            Windmill Whirl-Off {this.state.year}</div>

                        <div className="home2-whirloff-banner-headline-stat">
                            <Odometer value={grandTotal} format="(,ddd)" theme="minimal" />
                        </div>
                        sail revolutions counted<br />

                        <div className="home2-whirloff-banner-lower-text">
                            {lowerText}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Home2WhirlOffBanner