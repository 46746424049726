import React from 'react';
import './WhirlOffFeaturedMill.css';
import UnitsContext from '../../UnitsContext';
import { FormattedMessage } from 'react-intl';

class WhirlOffFeaturedMillImage extends React.Component {

    static context = UnitsContext;

    componentDidMount() {
    }

    render() {
        if (this.props.millToDisplay == null) {
            return "Loading";
        }
        else {
            return this.renderLoaded();
        }
    }

    renderLoaded() {

        var millToDisplay = this.props.millToDisplay;
        var contentDisplayMode = null;

        if (millToDisplay.info !== null) {
            if (millToDisplay.info.webcamHyperlinks !== null && millToDisplay.info.webcamHyperlinks.length > 0) {
                contentDisplayMode = "WEBCAM";
            }
            else if (millToDisplay.latestSailRotationReading.currentSpeedRpm > 0 && millToDisplay.info.videoHyperlinks != null && millToDisplay.info.videoHyperlinks.length > 0) {
                contentDisplayMode = "VIDEO";
            }
            else if (millToDisplay.info.photoHyperlinks !== null && millToDisplay.info.photoHyperlinks.length > 0) {
                contentDisplayMode = "PHOTO";
            }
            else {
                contentDisplayMode = "COMINGSOON";
            }

        }


        return (

            <>
                {
                    contentDisplayMode === "WEBCAM" && <>
                        <div className="whirloff-featured-mill-image-container">
                            <iframe src={millToDisplay.info.webcamHyperlinks[0].url} width="100%" height="100%" allow="autoplay" className="whirloff-featured-mill-image" scrolling="no" title={millToDisplay.info.webcamHyperlinks[0].text} style={{ border: "none" }} allowfullscreen=""></iframe>
                        </div>
                        <div className="whirloff-featured-mill-photo-copyright">
                            {millToDisplay.info.webcamHyperlinks[0] !== null && millToDisplay.info.webcamHyperlinks[0].attribution !== null && <>
                                <FormattedMessage
                                    id="whirloff.featuredMill.webcamOperatedBy"
                                    defaultMessage="Webcam operated by"
                                />&nbsp;{millToDisplay.info.webcamHyperlinks[0].attribution}</>}
                        </div>
                    </>
                }

                {
                    contentDisplayMode === "VIDEO" && <>
                        <div className="whirloff-featured-mill-image-container">
                            <iframe width="100%" height="100%" src={millToDisplay.info.videoHyperlinks[0].url} title={millToDisplay.info.videoHyperlinks[0].text} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div className="whirloff-featured-mill-photo-copyright">
                            {millToDisplay.info.videoHyperlinks[0] !== null && millToDisplay.info.videoHyperlinks[0].attribution !== null && <>
                                <FormattedMessage
                                    id="whirloff.featuredMill.video"
                                    defaultMessage="Video &copy;"
                                />&nbsp;{millToDisplay.info.videoHyperlinks[0].attribution}</>}
                        </div>
                    </>
                }

                {
                    contentDisplayMode === "PHOTO" && <>
                        <div className="whirloff-featured-mill-image-container">
                            <a href={millToDisplay.info.photoHyperlinks[0].attributionUrl}><img width="100%" height="100%" className="whirloff-featured-mill-image" alt={millToDisplay.info.photoHyperlinks[0].text} src={millToDisplay.info.photoHyperlinks[0].url} /></a>
                        </div>
                        <div className="whirloff-featured-mill-photo-copyright">Photo &copy; <a href={millToDisplay.info.photoHyperlinks[0].attributionUrl}>{millToDisplay.info.photoHyperlinks[0].attribution}</a></div>
                    </>
                }

                {
                    contentDisplayMode === "COMINGSOON" && <>
                        <div className="whirloff-featured-mill-image-container">
                            Photo / video for this mill coming soon
                        </div>
                    </>
                }


            </>
        )
    }
}

export default WhirlOffFeaturedMillImage