import React from 'react'
import Odometer from 'react-odometerjs';
import './WhirlOffMolenList.css'
import UnitsContext from '../../UnitsContext';
import { FormattedMessage } from 'react-intl';
import UnitLocalisationLogic from '../../components/unitlocalisationLogic';

class WhirlOffMolenList extends React.Component {

  static contextType = UnitsContext;

  render() {
    if (this.props.whirloff == null) {
      return <span>Loading ...</span>
    }
    else {
      return this.renderLoaded();
    }
  }

  renderLoaded() {
    
    return (
      <div className="whirloff-molen-list">
        <table className="whirloff-table">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              {this.props.showWeekdayDistribution && <th align="center">Weekdays</th>}
              {this.props.showWind && <th>Wind</th>}
              <th align="right"><FormattedMessage
                id={"common.sailSpeed." + new UnitLocalisationLogic().getLocalisedSailRotationSpeed(0, this.context).unitLocalisationKey}
                defaultMessage={new UnitLocalisationLogic().getLocalisedSailRotationSpeed(0, this.context).units}
              /></th>
              <th align="right"><FormattedMessage
                id="whirloff.molenList.today"
                defaultMessage="Today"
              /></th>
              <th align="right"><FormattedMessage
                id="whirloff.molenList.thisYear"
                defaultMessage="This year"
              /></th>
            </tr>
          </thead>
          <tbody>
            {this.props.whirloff.map(item => (
              <tr key={item.molenId}>
                <td width="30px" align="right">{item.rank}</td>
                <td align="left" width={this.props.flagWidth + 6}><img src={"/img/flags/" + item.country.toLowerCase() + ".png"} width={this.props.flagWidth - 2} style={{ "verticalAlign": "middle" }} title={"Flag"} alt={"Flag"}></img>
                </td>
                <td width="*"><div className="whirloff-table-mill-name">{item.name.replace(/[()]/g, '')}</div></td>
                <td width="12%" align="right">
                  {item.latestSailRotationReading.currentSpeedRpm > 0 &&
                    <Odometer value={new UnitLocalisationLogic().getLocalisedSailRotationSpeed(item.latestSailRotationReading.currentSpeedRpm, this.context).value} format="(dd)" theme="minimal" />
                  }
                </td>
                <td width="16%" align="right">
                  {item.latestSailRotationReading.revCountToday > 0 &&
                    <Odometer value={item.latestSailRotationReading.revCountToday} format="(,ddd)" theme="minimal" />
                  }
                </td>
                <td width="18%" align="right">
                  <Odometer value={item.latestSailRotationReading.revCountThisYear} format="(,ddd)" theme="minimal" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <br />

      </div>

    )
  }
}



export default WhirlOffMolenList